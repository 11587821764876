<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Criativos</h1>
        <!--end::Title-->
        <div class="mt-3" v-if="creatives.length > 0">
          <p class="mb-0">
            <strong>Resultados:</strong> {{ creatives.length }}/{{ total }}
          </p>
        </div>
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <!--begin::Filter menu-->
        <div class="m-0">
          <!--begin::Menu toggle-->
          <a
            href="#"
            @click.prevent="isFiltersOpen = !isFiltersOpen"
            class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
          >
          <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          <!--end::Svg Icon-->Filtrar</a>
          <!--end::Menu toggle-->
          <!--begin::Menu 1-->
          <div
            class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
            :class="{
              'show': isFiltersOpen
            }"
            style="position: absolute; top: 40px; right: 0;"
          >
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
            </div>
            <!--end::Header-->
            <!--begin::Menu separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Menu separator-->
            <!--begin::Form-->
            <div class="px-7 py-5">
              <!--begin::Input group-->
              <div class="mb-10">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Anunciante:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.advertiser"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-10">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Cliente:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.client"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-10">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Data inicial de atualização:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.start_date" />
                  <div class="mt-3">
                    <button type="button" class="btn btn-sm btn-primary" @click="filters.start_date = ''" v-if="filters.start_date">Limpar</button>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-10">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Data final de atualização:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.end_date" />
                  <div class="mt-3">
                    <button type="button" class="btn btn-sm btn-primary" @click="filters.end_date = ''" v-if="filters.end_date">Limpar</button>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" @click="applyFilters">Aplicar</button>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Form-->
          </div>
          <!--end::Menu 1-->
        </div>
        <!--end::Filter menu-->
        <!--begin::Secondary button-->
        <!--end::Secondary button-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && creatives.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else-if="!loader && creatives.length === 0">
          <p class="text-center pt-5">Nenhum criativo encontrado</p>
        </template>
        <template v-else>
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th class="min-w-100px">Anunciante</th>
                  <th class="min-w-70px">Nome</th>
                  <th class="min-w-100px">Criativo</th>
                  <th class="min-w-70px">Layout</th>
                  <th class="min-w-70px">Status</th>
                  <th class="min-w-70px">ID GOOGLE</th>
                  <th class="min-w-70px">Data de atualização</th>
                  <th class="min-w-70px">Ações</th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="fw-semibold text-gray-600">
                <!--begin::Table row-->
                <tr v-for="(creative, index) in creatives" :key="index">
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'advertisers.show', params: { id: creative.advertiser.id } }"
                      >
                        {{ creative.advertiser.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>{{ creative.name }}</span>
                  </td>
                  <td>
                    <span>
                      <a :href="creative.file" target="_blank">
                        <img :src="creative.file" :alt="creative.name" width="100" />
                      </a>
                    </span>
                  </td>
                  <td>
                    <span>{{ creative.layout }}</span>
                  </td>
                  <td>
                    <span>{{ creative.status | formatStatus }}</span>
                  </td>
                  <td>
                    <span>{{ creative.google_id }}</span>
                  </td>
                  <td>
                    <span>{{ creative.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                  </td>
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'creatives.show', params: { id: creative.id } }"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fas fa-eye px-0"></i>
                      </router-link>
                    </span>
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import CreativesService from '@/modules/creatives/services/creatives-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Button,
    Loader,
    MainLayout
  },
  metaInfo () {
    return {
      title: 'Criativos'
    }
  },
  data () {
    return {
      creatives: [],
      filters: {
        advertiser: '',
        client: '',
        end_date: '',
        start_date: ''
      },
      isFiltersOpen: false,
      isLoadMoreVisible: false,
      loader: false,
      page: 1,
      total: 0
    }
  },
  created () {
    this.getCreatives()
  },
  mounted () {
    document.getElementsByClassName('date_picker_date').forEach(item => {
      item.flatpickr({
        dateFormat: 'd/m/Y'
      })
    })
  },
  methods: {
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.page = 1
      this.creatives = []
      this.getCreatives()
    },
    /**
     * Get creatives
     */
    async getCreatives () {
      this.loader = true
      try {
        const creatives = await CreativesService.getCreatives({
          advertiser: this.filters.advertiser,
          client: this.filters.client,
          end_date: this.filters.end_date,
          page: this.page,
          start_date: this.filters.start_date
        })
        this.creatives = [...this.creatives, ...creatives.data]
        this.isLoadMoreVisible = creatives.meta.current_page !== creatives.meta.last_page
        this.total = creatives.meta.total
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCreatives()
    },
    /**
     * Reset
     */
    reset () {
      this.isFiltersOpen = false
      this.filters.advertiser = ''
      this.filters.client = ''
      this.filters.end_date = ''
      this.filters.start_date = ''
      this.page = 1
      this.creatives = []
      this.getCreatives()
    }
  }
}
</script>
